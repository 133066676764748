//Async action creator
/***************************************************************************
 * File : accountsetupAction.js
 * Description :-
 * 1. This file is used as Async action creator or Middleware
 * Steps : -
 * 1. Invoke API call
 * 2. Dispatch action type(same as mentioned in Reducer file e.g. GET_COUNTRIES )
 *    in order to update state
 * @author Gauri Yadav
 **************************************************************************/

import { HIDE_RADIO_BUTTONS, LOADING_ELEVATE_CIRCLE_CODE, LOAD_CREATE_ACCOUNT, LOAD_ELEVATE_CIRCLE_ERROR, LOAD_EMAIL_DOMAIN, LOAD_RECAPTCHA_CREDETAIL } from '../reducers/accountsetupReducer';
import { getApiCall, postApiCall } from '../utils/index';

export const GET_COUNTRIES = 'GET_COUNTRIES';
export const fetchCountries = url => {
    return dispatch => {
      postApiCall(url,{}).then(res => {
                return res.json(); //If call is success then move to next promise
            })
            .then(res => {
                //dispatch willl call reducer when data is available.
                dispatch({ type: GET_COUNTRIES, countries: res.GetCountryListRes.CountryList });
           }).catch(err => {
            // Do something for an error here
              window.open('/errorpage','_self')

          });

    }
}

export const fetchUserType = (url, payLoad, isAuthorization) => {
  return dispatch => new Promise((resolve, reject) => {
    if(isAuthorization) {
      dispatch({ type : "AUTH_INPUT_LOADING" , authInputLoading : true})
    } else {
      dispatch({ type : "LOADING_SPINNER" , loadingSpinner : true})
    }
    
    
    postApiCall(url, payLoad).then(res => {
      return res.json();
    }).then(res => {
      let userDetail = {
        UserType: res.userType,
        CompanyId: res.companyId,
        CompanyName: res.companyName,
        theater: res.theater,
        sapAccountId: res.sapAccountId
      }
      dispatch({ type: "GET_USER_TYPE", userType : userDetail })
      dispatch({ type : "LOADING_SPINNER" , loadingSpinner : false})
      dispatch({ type : "AUTH_INPUT_LOADING" , authInputLoading : false})
      return resolve(res);
    }
    ).catch(err => {
      // Do something for an error here
      //window.open('/errorpage','_self')
      return reject(err)
    });
  })
}

export const isEmailAPublicDomain = (url, payLoad) => {
  return dispatch => {
    postApiCall(url, payLoad).then(res => {
      return res.json();
    }).then(res => {
      dispatch({ type: "IS_PUBLIC_DOMAIN", isEmailPublicDomain : res.publicDomain? "True": "" })
    }
    ).catch(err => {
      // Do something for an error here
        window.open('/errorpage','_self')
    });
  }
}


export const isEmailAlreadyExist = (url, payLoad) => {
  return dispatch => {
    dispatch({ type : "LOADING" , loadingStarts : true})
    postApiCall(url, payLoad).then(res => {
      return res.json();
    }).then(res => {
      dispatch({ type: "IS_EMAIL_EXIST", isEmailAlreadyExists : res.emailExist? "True": "" })
      dispatch({ type: LOAD_EMAIL_DOMAIN, data: res });
      dispatch({ type : "LOADING" , loadingStarts : false})
    }
    ).catch(err => {
      // Do something for an error here
        window.open('/errorpage','_self')
    });
  }
}

export const isRestrictedEmailDomain = (url) => {
  return dispatch => {  
  dispatch({ type : "LOADING" , loadingStarts : true})
  postApiCall(url).then(res => {
    return res.json();
  }).then(res => {
    dispatch({ type: "IS_RESTRICTED_EMAIL_DOMAIN", isRestrictedEmailDomains : res.isRestrictedEmailDomain})
    dispatch({ type : "LOADING" , loadingStarts : false})

  }
  ).catch(err => {
    // Do something for an error here
      //window.open('/errorpage','_self')
  });
  }
}

export const resetIsRestrictedEmailDomain = () => {
  return dispatch => {dispatch({type: 'RESET_IS_RESTRICTED_EMAIL_DOMAIN'})}
}

export const resetValues = () =>{
 return dispatch => {dispatch({type: "RESET_VALUES"})}
}

export const resetElevateAuthError = () => {
  return dispatch => {dispatch({type: "RESET_ELEVATE_AUTH_ERROR"})}
}


export const removeEmailExistError = () =>{
  return dispatch => {dispatch({type: "REMOVE_EMAIL_EXIST_ERROR"})}
 }
 
 export const validateAuthCode = (url,payLoad) => {
   return dispatch => {
   dispatch({ type : "AUTH_INPUT_LOADING" , authInputLoading : true})
   postApiCall(url,payLoad).then(res => {
     return res.json()
   }).then((res) => {
     if(res.GetCompanyFromAuthCodeRes.ResMessage === "Failure"){
       res = "Invalid Authorization Code"
     }
     else if(res.GetCompanyFromAuthCodeRes.ResMessage === "Success" && (res.GetCompanyFromAuthCodeRes.CompanyType === "Distributor" || res.GetCompanyFromAuthCodeRes.CompanyType === "JPartner")){
       res = "Invalid Role"
     }
     dispatch({type : "VALIDATE_AUTH_CODE",authCode : res})
     dispatch({ type : "AUTH_INPUT_LOADING" , authInputLoading : false})
   }).catch(err => {
     // Do something for an error here
    window.open('/errorpage','_self')
   
   });
 }
 }
 
  export const validateSerialNum = (url,payLoad) => {
    return dispatch => new Promise((resolve, reject) => {

     dispatch({type : "SERIAL_NUM_LOADING", serialNumLoading : true})
     postApiCall(url,payLoad).then(res => {
       return res.json()
     }).then((res) => {
       dispatch({type : "VALIDATE_SERIAL_NUM",serialNumErrMsg : res.ValidateSerialNumberRes.isValidserailorSSRN,serialNumRes : res.ValidateSerialNumberRes})
       dispatch({type : "SERIAL_NUM_LOADING", serialNumLoading : false})
       return resolve(res);
     }).catch(err => {
       // Do something for an error here
      window.open('/errorpage','_self')
      return reject(err);
     });
    })
}

export const removeRadioButtonsError = () =>{
  return dispatch => {dispatch({type: "REMOVE_RADIO_ERRORS"})}
 }

 export const  resetSerialNumMsg= () =>{
  return dispatch => {dispatch({type: "RESET_SERIAL_NUM_MSG"})}
 }

 /**
  * This is used to validate elevate circle code
  * @param {Object: AccountRequestBean{elevateCircleCode: "Example" }} payload 
  * @returns null
  */
  export const validateElevateCircleCode = (payload) => {
    return dispatch => new Promise((resolve, reject) => {
      dispatch({ type :LOADING_ELEVATE_CIRCLE_CODE , data : true})
      dispatch({ type: LOAD_ELEVATE_CIRCLE_ERROR, data: null }) 
      postApiCall("/validateElevatedCircleCode", payload).then(res => {
        return res.json();
      }).then(res => {
        if(res.ResMessage === "Success") {
          dispatch({ type: LOADING_ELEVATE_CIRCLE_CODE , data: false})
          return resolve(res)  
        }
        if(res.ResMessage === "Failure") {
          dispatch({ type: LOADING_ELEVATE_CIRCLE_CODE , data: false})
          dispatch({ type: LOAD_ELEVATE_CIRCLE_ERROR, data: "Following elevate circle code is not valid: "+ res.FailedCircleCodes })
          return reject(res); 
        }
      }
      ).catch(err => {
        // Do something for an error here
        dispatch({ type : LOADING_ELEVATE_CIRCLE_CODE , data : false})
        window.open('/errorpage','_self')
        return reject(err);
      });
    })
  }


export const showAuthCodeErrorMsg = (isAuthorization) =>{
 
  return dispatch => {
    if(isAuthorization) {
      dispatch({type : "VALIDATE_AUTH_CODE", authCode : "Invalid Authorization Code"})
    } else {
      dispatch({type : "VALIDATE_SERIAL_NUM",serialNumErrMsg: "false", serialNumRes : ""})
    }
  }
 }

/**
 * This is used to reset elevate input error
 * @returns NUll
 */
export const  resetElevateErrorMsg = () => {
  return dispatch => {dispatch({type: LOAD_ELEVATE_CIRCLE_ERROR, data: "" })}
 }


/**
 * 
 */
 export const getRecaptchaCredentials = () => {
  return dispatch => {
    getApiCall("/getRecaptchaDetails")
      .then(res => {
        return res.json();
      })
      .then(response => {
        dispatch({ type: LOAD_RECAPTCHA_CREDETAIL, data: response })
      })
      .catch(err => {
        console.log("Error", err)
      })
  }
 }


 export const loadHideRadioButtons = (payload) => {
  return dispatch => {dispatch({type: HIDE_RADIO_BUTTONS, data: payload})}
}

/**
 * 
 * @param {Object} payload 
 * @returns response
 */
export const postSubmitAccount = (payload) => {

  return dispatch => new Promise((resolve, reject) => {
    dispatch({ type : "LOADING" , loadingStarts : true})
    postApiCall("/isEmailExists", payload).then(res => {
      return res.json()
    }).then((res) => {
      dispatch({ type: LOAD_CREATE_ACCOUNT, data: res})
      dispatch({ type : "LOADING" , loadingStarts : false})
      return resolve(res);
    }).catch(err => {
      // Do something for an error here
     window.open('/errorpage','_self')
     dispatch({ type : "LOADING" , loadingStarts : false})
     return reject(err);
    });
   })

}

export const resetError = () => {
  return dispatch => dispatch({ type: LOAD_CREATE_ACCOUNT, data: null})
}

export const getUnlockUserEmail = (url) => {
  return dispatch => {
    dispatch({ type : "LOADING" , loadingStarts : true})
      getApiCall(url).then(res => {
          return res.text();
      }).then(response => {
          dispatch({type : 'GET_UNLOCKED_USER_INFO', unlockedUserInfo : response})
          dispatch({ type : "LOADING" , loadingStarts : false})
      }).catch(err => {
        console.log("err", err)
          // Do something for an error here
          dispatch({ type : "LOADING" , loadingStarts : false})
          window.open('/errorpage','_self')
     
      });
  }
}
 
 
 
 
 
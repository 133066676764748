import React from "react";
import ReactTooltip from 'react-tooltip';
import DisclaimerDomainDialog from "./DisclaimerDialogComponent";
import FooterHyperlinks from "./FooterHyperlinksComponent";

//Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchCountries, fetchUserType, getRecaptchaCredentials, getUnlockUserEmail, isEmailAPublicDomain, isEmailAlreadyExist, isRestrictedEmailDomain, loadHideRadioButtons, removeEmailExistError, removeRadioButtonsError, resetElevateAuthError, resetElevateErrorMsg, resetIsRestrictedEmailDomain, resetSerialNumMsg, resetValues, showAuthCodeErrorMsg, validateAuthCode, validateElevateCircleCode, validateSerialNum } from '../actions/accountsetupAction';
//Utils
import ReCAPTCHA from "react-google-recaptcha";
import BarLoader from 'react-spinners/BarLoader';
import { isEnglishChar } from '../utils';
import { EMBARGO_COUNTRY_LIST, SITE_KEY } from '../utils/constants';
/*******************************************************
 * Screen - Account Set Up 
 * Description - This is the first step needed for User 
 *               Registration.
 * @author : Gauri Yadav
 ******************************************************/

let accountSetUpObj = {};
let accountCreationObj ={};
var isNavigatedBackFromAcctCreation = '';
const recaptchaRef = React.createRef();
class AccountSetUpComponent extends React.Component {
	constructor(props) {
		super();
		this.showDialog = this.showDialog.bind(this);
		this.enableNextButton = this.enableNextButton.bind(this);
		this.navigateToAcctCreationOrCloudProvider = this.navigateToAcctCreationOrCloudProvider.bind(this);
		this.onChangeField = this.onChangeField.bind(this);
		this.validateEmail = this.validateEmail.bind(this);
		this.toggleGuestUserBox = this.toggleGuestUserBox.bind(this);
		this.renderCountryList = this.renderCountryList.bind(this);
		this.checkUserrole = this.checkUserrole.bind(this);
		this.validateMandatoryFieldsAndError = this.validateMandatoryFieldsAndError.bind(this);
		this.checkForEngChar = this.checkForEngChar.bind(this);
		this.fetchUserRole = this.fetchUserRole.bind(this);
		this.isEmailAPublicDomain = this.isEmailAPublicDomain.bind(this);
		this.isEmailAlreadyExist = this.isEmailAlreadyExist.bind(this);
		this.isRestrictedEmailDomain = this.isRestrictedEmailDomain.bind(this);
		this.resetValues = this.resetValues.bind(this);
		this.resetElevateAuthError = this.resetElevateAuthError.bind(this);
		this.resetIsRestrictedEmailDomain = this.resetElevateAuthError.bind(this);
		this.removeEmailExistError = this.removeEmailExistError.bind(this);
		this.validateAuthCode = this.validateAuthCode.bind(this);
		this.validateSerialNum = this.validateSerialNum.bind(this);
		this.preventPasting = this.preventPasting.bind(this);
		this.verifyCaptcha = this.verifyCaptcha.bind(this);
		this.state = {
			enableNextBtn: false,
			emailAdd : "",
			renterEmailAdd : "",
			authCode : "",
			serialNum : "",
			country : -1,
			countryName : "- Please Specify -",
			phoneCode : null,
			erroremailAdd: false,
			errorrenterEmailAdd : false,
			errorEmailDontMatch : false,
			errorcountry : false,
			incorrectEmailAdd : false,
			errorEmailInternalUsers: false,
			errorReEmailInternalUsers: false,
			incorrectRenterEmailAdd : false,
			errorNonEngauthCode : false,
			errorNonEngserialNum : false,
			validateSerialNumberUI: false,
			isJuniperDomain : false,
			dialogName : '',
			errorOnPage : true,
			// isChecked : false,
			accountSetUpRadioCollection : "Authorization Code",
			disableAuthCode : false,
			disableSerialNum : true,
			openDialog : false,
			openEmailDialog : false,
			emailFlag : "individual",
			elevateInputError: false,

			isGuestUserReq: false,
			isCloudUserReq: false,
			isLabUserReq: false,
			isEvaluationUserReq: false,
			showUserTypeRadioBtn: false,
			companyId: "",
			companyName: "",
			sapAccountId: "",
			emailDomain: "",
			elevateCircleInputError:false,
			captchaToken: null,
			hideRadioButtons: false
		}
		this.authInput = React.createRef();
		this.serialInput = React.createRef();
		this.elevateCircleCodeInput = React.createFactory();
	}

	componentDidMount = () => {
		window.scrollTo(0,0);
		this.props.removeEmailExistError();
		this.props.resetIsRestrictedEmailDomain();
		this.getRecaptchaDetails()
		this.decryptToken();
		if(undefined !== this.props.location.state && undefined !== this.props.location.state.applicationData){
			accountCreationObj =  this.props.location.state.applicationData.accountCreationObj;
			accountSetUpObj = this.props.location.state.applicationData.accountSetUpObj;
			isNavigatedBackFromAcctCreation = this.props.location.state.applicationData.isNavigatedBackFromAcctCreation
		}else{
			accountCreationObj = {}
		}
		if(isNavigatedBackFromAcctCreation === "false" || this.props.location.state === undefined){
			//to reset the values for user role on account setup page
			this.resetValues();
			accountCreationObj = {}
			accountSetUpObj = {}
		}else{			
			if(this.props.location.state !== undefined && undefined !== this.props.location.state.applicationData){
			let parsedAccountSetupObj = this.props.location.state.applicationData.accountSetUpObj;
				this.setState({
					emailAdd : parsedAccountSetupObj.emailAdd,
					renterEmailAdd : parsedAccountSetupObj.emailAdd,
					country : parsedAccountSetupObj.countryCode,
					countryName : parsedAccountSetupObj.countryName,
					phoneCode : parsedAccountSetupObj.phoneCode,
					accountSetUpRadioCollection : accountSetUpObj.selectedRadioBtn,
					authCode : parsedAccountSetupObj.authCode,
					serialNum : parsedAccountSetupObj.serialNumber,
					isChecked : parsedAccountSetupObj.isLabUser,
					emailFlag : parsedAccountSetupObj.isIndividualEmail ? "individual" : "generic",
					elevatedCircleCode: parsedAccountSetupObj.elevatedCircleCode,
					elevatedCircleCodeChek: parsedAccountSetupObj.elevatedCircleCodeChek
				})
				if(parsedAccountSetupObj.authCode === "" && ( (this.props.userType && this.props.userType.UserType === "End User") || this.props.isEmailPublicDomain === "True")){
					this.setState({
						disableAuthCode : true
					})
				}
				if(parsedAccountSetupObj.serialNumber === ""){
					this.setState({disableSerialNum : true})
				}
				else if(parsedAccountSetupObj.serialNumber !== ""){
					this.setState({disableSerialNum : false})
				}
				Object.keys(parsedAccountSetupObj).forEach((key) => {
					let val = parsedAccountSetupObj[key]
					if((key === "emailAdd" || key === "countryCode") && val && val.toString().trim() !== ""){
						this.setState({
							errorOnPage : false
						})
					}
				})
			}
			//this.props.history.replace('/',{}); // To reset history data on reload of page
		}
		this.props.resetSerialNumMsg();
		var url = '/getCountries';
	    this.props.fetchCountries(url);
	}

	componentDidUpdate(prevProps){

		if(this.props.isRestrictedEmailDomains === "NO"){
			this.isEmailAlreadyExist(this.state.emailAdd)
			this.props.resetIsRestrictedEmailDomain()
		}

		if(prevProps.authCode !== this.props.authCode){
			let applicationData = {};
			if(this.props.authCode.GetCompanyFromAuthCodeRes !== undefined){
				accountSetUpObj.loggedInUser = this.props.authCode.GetCompanyFromAuthCodeRes.CompanyType;
				accountSetUpObj.companyId = this.props.authCode.GetCompanyFromAuthCodeRes.RecordId;
				accountSetUpObj.companyName = this.props.authCode.GetCompanyFromAuthCodeRes.CompanyName;
				applicationData.accountSetUpObj = accountSetUpObj;
				applicationData.accountCreationObj = accountCreationObj;
				this.props.history.push({
					pathname: '/accountinformation',
					state: { applicationData : applicationData },
				  })
			}
		}
		
		if(prevProps.serialNumErrMsg !== this.props.serialNumErrMsg){
				let applicationData = {};
				let {serialNumRes} = this.props
			if(this.props.serialNumErrMsg === "true"){
				if(serialNumRes.softwareRoles === "JUNOS"){
				accountSetUpObj.serialNumRes = {
					softwareRoles : serialNumRes.softwareRoles,
					isEntitlementcheck : serialNumRes.isEntitlementcheck,
					isContainer : serialNumRes.isContainer				
				}
			  }
				applicationData.accountSetUpObj = accountSetUpObj;
				applicationData.accountCreationObj = accountCreationObj;
				this.props.history.push({
					pathname: '/accountinformation',
					state: { applicationData : applicationData },
				  })
			}
		}

		if(prevProps.emailDomain && prevProps.emailDomain.emailDomain !== this.props.emailDomain.emailDomain) {
			this.setState({ emailDomain: prevProps.emailDomain }, this.fetchUserRole() );
		}

		if(prevProps.unlockedUserInfo && prevProps.unlockedUserInfo !== this.props.unlockedUserInfo) {
			this.setState({ emailAdd: this.props.unlockedUserInfo, renterEmailAdd: this.props.unlockedUserInfo }, this.isEmailAPublicDomain(this.props.unlockedUserInfo))
		}
		
	}

	componentWillUnmount() {
		this.setState({ showUserTypeRadioBtn: true })
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		
		if(nextProps.userType && nextProps.emailDomain && nextProps.emailDomain.emailDomain !== prevState.emailDomain) {
			if(nextProps.userType && nextProps.userType.UserType === "Customer" && nextProps.hideRadioButtons) {
				return { showUserTypeRadioBtn: false}
			} else {
				return { showUserTypeRadioBtn: true}
			}
		}

		return null;
	}

	decryptToken = () => {
		this.props.getUnlockUserEmail('/getUnlockUserInfo');
	}

	removeEmailExistError(){
		this.props.removeEmailExistError();
	}


	getuserTypeInfo = (accountSetUpObj, payLoadUser, isAuthorization, userRole, isCloudUser) => {
		this.props.fetchUserType("/getUserTypeInfo",payLoadUser, isAuthorization)
			.then(res => {
				if(res.companyName) {
					userRole = res.userType;
					accountSetUpObj.companyId = res.companyId;
					accountSetUpObj.companyName = res.companyName;
					accountSetUpObj.sapAccountId = res.sapAccountId;
					accountSetUpObj.loggedInUser = userRole;
					
					if(userRole === "End User" || this.props.isEmailPublicDomain === "True"){
					
						if(this.state.accountSetUpRadioCollection === "Authorization Code"){
							//auth flow
									// accountSetUpObj.isLabUser = '';
									accountSetUpObj.authCode = this.state.authCode
									accountSetUpObj.serialNumber = ""
						}
						if(this.state.accountSetUpRadioCollection === "Product Serial Number"){
							//serial flow
									accountSetUpObj.authCode = "";
									// accountSetUpObj.isLabUser = '';
									accountSetUpObj.serialNumber  = this.state.serialNum;
						}
						//check if user type is supplier then we have to pass authcode
						if(userRole === "Supplier") {
							accountSetUpObj.authCode = this.state.authCode
						}
			
						if(isCloudUser){
							let applicationData = {};
							accountSetUpObj.loggedInUser = "Cloud User";
							applicationData.accountSetUpObj = accountSetUpObj;
							applicationData.accountCreationObj = accountCreationObj;
							this.props.history.push({
								pathname: '/cloudusercreation',
								state: { applicationData : applicationData },
							  })
						} else {
							let applicationData = {};
							applicationData.accountSetUpObj = accountSetUpObj;
							applicationData.accountCreationObj = accountCreationObj;
							this.props.history.push({
								pathname: '/accountinformation',
								state: { applicationData : applicationData },
							  })
						}
					}
					else{
						let applicationData = {};
						// accountSetUpObj.isLabUser = '';
						accountSetUpObj.authCode = this.state.authCode;
						accountSetUpObj.serialNumber  = "";

						applicationData.accountSetUpObj = accountSetUpObj;
						applicationData.accountCreationObj = accountCreationObj;

						//if user checks cloud radio button, then redirect to cloud UI
						if(isCloudUser){
							accountSetUpObj.loggedInUser = "Cloud User";
							this.props.history.push({
								pathname: '/cloudusercreation',
								state: { applicationData : applicationData },
							  })

							return;
						}
						
						//check if user type is supplier then we have to pass authcode
						if(userRole === "Supplier") {
							accountSetUpObj.authCode = this.state.authCode
						}
			
						this.props.history.push({
							pathname: '/accountinformation',
							state: { applicationData : applicationData },
						  })
					}
				} else {
					this.props.showAuthCodeErrorMsg(isAuthorization)
				}

			})
			.catch(err => {
				console.log("err", err)
			})
	}	
	getRecaptchaDetails() {
		this.props.getRecaptchaCredentials();
	}
	/**********************************************************
     * Triggered : On click of Next button
     * Description : This function is used to navigate back to 
     *               Account Creation page or Cloud Provider screen
	 * 				 on the basis of radio button chosen by user.
     * 
     *********************************************************/
	navigateToAcctCreationOrCloudProvider(checkCirleCode = true) {

		let userRole = "Base User"
		accountSetUpObj.emailAdd = this.state.emailAdd;
		accountSetUpObj.countryCode = this.state.country;
		accountSetUpObj.countryName = this.state.countryName;
		accountSetUpObj.phoneCode = this.state.phoneCode
		accountSetUpObj.loggedInUser = userRole;
		accountSetUpObj.isLabUser = this.state.accountSetUpRadioCollection === "Lab User Access"
		accountSetUpObj.companyId = 0;
		accountSetUpObj.companyName = "";
		accountSetUpObj.sapAccountId = "";
		accountSetUpObj.isPublicUser = this.props.isEmailPublicDomain;
		accountSetUpObj.selectedRadioBtn = this.state.accountSetUpRadioCollection
		accountSetUpObj.isIndividualEmail = this.state.emailFlag === "individual" ? true : false
		accountSetUpObj.elevatedCircleCode = this.state.elevatedCircleCode
		accountSetUpObj.elevatedCircleCodeChek = this.state.elevatedCircleCodeChek
		accountSetUpObj.userType = this.props.userType.UserType
		// This is the change request for okta sprint 5
		accountSetUpObj.isGuestUserReq =this.state.accountSetUpRadioCollection === "Guest User Access"
		accountSetUpObj.isCloudUserReq = this.state.accountSetUpRadioCollection === "Cloud Marketplace"
		accountSetUpObj.isLabUserReq = this.state.accountSetUpRadioCollection === "Lab User Access"
		accountSetUpObj.isEvaluationUserReq = this.state.accountSetUpRadioCollection === "Evaluation User Access"
		accountSetUpObj.isEndUserReq = this.state.accountSetUpRadioCollection === "Product Serial Number"
		accountSetUpObj.captchaToken = this.state.captchaToken
		accountSetUpObj.hideRadioButtons = this.props.hideRadioButtons

		accountSetUpObj.serialNumRes = {
			softwareRoles : null,
			isEntitlementcheck : null,
			isContainer : null				
		}

		// check if user enters elevate cirle code, then validate circle code first
		if(checkCirleCode) {
			this.handleOnBlurElevateCircleCode();
			return;
		} 
		
		//set cloud user
		let isCloudUser = this.state.accountSetUpRadioCollection === "Cloud Marketplace";
		if( (this.state.accountSetUpRadioCollection === "Authorization Code" || this.state.accountSetUpRadioCollection === "Product Serial Number") && this.state.showUserTypeRadioBtn !== false ){
			let payLoadUser = {
				"emailAddress": this.state.emailAdd,
				"emailDomain": this.props.emailDomain?this.props.emailDomain.emailDomain : "",
				"authorizationCode": this.state.authCode,
				"countryCode": this.state.country
			}

			let isAuthorization = true;

			if(this.state.accountSetUpRadioCollection === "Product Serial Number") {
				isAuthorization = false
				this.validateSerialNum(this.state.serialNum)
				.then(res => {
					if(res.ValidateSerialNumberRes.isValidserailorSSRN === true) {
						let applicationData = {};
						accountSetUpObj.serialNumber = this.state.serialNum
						accountCreationObj.userRole = "End User";
						accountCreationObj.loggedInUser = "End User";
						accountCreationObj.userType = "End User" 
						accountSetUpObj.authCode = "";
						accountSetUpObj.serialNumRes = {
							softwareRoles: res.ValidateSerialNumberRes.softwareRoles,
							isEntitlementcheck: res.ValidateSerialNumberRes.isEntitlementcheck,
							isContainer: res.ValidateSerialNumberRes.isContainer
						}
						applicationData.accountSetUpObj = accountSetUpObj;
						applicationData.accountCreationObj = accountCreationObj;
						this.props.history.push({
							pathname: '/accountinformation',
							state: { applicationData : applicationData },
						})
					}
				})
				.catch(err => {
					console.log(err)
				})
			} else {
				this.getuserTypeInfo(accountSetUpObj, payLoadUser, isAuthorization, userRole, isCloudUser)
			}
			
		} else {
		
			let applicationData = {};
			// accountSetUpObj.isLabUser = '';
			accountSetUpObj.authCode = "";
			accountSetUpObj.serialNumber  = "";
			applicationData.accountSetUpObj = accountSetUpObj;
			applicationData.accountCreationObj = accountCreationObj;
			//if user checks cloud radio button, then redirect to cloud UI
			if(isCloudUser){
				accountSetUpObj.loggedInUser = "Cloud User";
				this.props.history.push({
					pathname: '/cloudusercreation',
					state: { applicationData : applicationData },
				  })

				return;
			}

			/**
			 * 
			 */
			if(!this.state.showUserTypeRadioBtn) {
				applicationData.accountSetUpObj.companyName = this.props.userType.CompanyName
				applicationData.accountSetUpObj.companyId = this.props.userType.CompanyId
				applicationData.accountSetUpObj.sapAccountId = this.props.userType.sapAccountId
				applicationData.accountSetUpObj.userType = this.props.userType.UserType
				applicationData.accountSetUpObj.loggedInUser = this.props.userType.UserType
			}

			this.props.history.push({
				pathname: '/accountinformation',
				state: { applicationData : applicationData },
			  })
	}
}

/***************************************************************************
     * Triggered : OnPaste of Reenter- Email Address
     * Description : This function is used pasting inside Re-enter email address
     **************************************************************************/
	preventPasting(event){
		event.preventDefault();
	}

	/***************************************************************************
     * Triggered : OnChange of inpit fields
     * Description : This function is used to validate all the errors and mandatory fields.
     **************************************************************************/

	validateMandatoryFieldsAndError(){
		let userRole = null !== this.props.userType ? this.props.userType.UserType : ""
		var allRequiredConditionsFulfilled = true;
		let {emailAdd,renterEmailAdd,country} = this.state
		if(this.state.incorrectEmailAdd || this.state.errorEmailInternalUsers || this.state.errorReEmailInternalUsers || this.state.incorrectRenterEmailAdd || this.state.errorEmailDontMatch
			|| this.state.errorNonEngauthCode || this.state.errorNonEngserialNum || this.state.erroremailAdd ||
			this.state.errorrenterEmailAdd || this.state.errorcountry || emailAdd === ""
		    ||  renterEmailAdd === ""
			|| country === "-1" || country === -1 || ((userRole === "End User" || this.props.isEmailPublicDomain === "True") && 
			this.state.accountSetUpRadioCollection === "Authorization Code" && this.state.authCode === "") || 
			(this.state.accountSetUpRadioCollection === "Product Serial Number" && this.state.serialNum === "")){
			allRequiredConditionsFulfilled = false;
		}
		if(allRequiredConditionsFulfilled){
			this.setState({errorOnPage : false})
		}else{
			this.setState({errorOnPage : true})
		}
	}
	/***************************************************************************
     * Triggered : On click of Ok Got It ! button of Public Email Domain Dialog
     * Description : This function is used to activate Next button.
     * 
     **************************************************************************/
	enableNextButton() {
			this.setState({ enableNextBtn: true });
	}

	/***************************************************************************
     * Triggered : On click of Public Email Disclaimer link
     * Description : This function is used to open public email domain dialog
	 * 				 box.
     **************************************************************************/
	showDialog(element) {
		if(element !== undefined){
			this.setState({
				openDialog : true
			})
		}
	}
	closeDialog(){
		this.setState({
			openDialog : false
		})
	}
	 /*****************************************************************
     * Triggered : On change of any Input form field
     * Description : This function is used to update the field 
     *               value and instantly remove the error associated with
     *               that field.
     ******************************************************************/
    onChangeField(event){
		event.persist();
		var errorString = "error"+event.target.name;
		if(event.target.value.trim() === "" || event.target.value === "-1" || event.target.value === -1){
			this.setState({[errorString] : true},()=>{
				this.validateMandatoryFieldsAndError();
			});
		}else{
			this.setState({[errorString] : false},()=>{
				this.validateMandatoryFieldsAndError();
			});
		}
		if(event.target.name === "emailAdd"){
		this.removeEmailExistError();
		this.props.resetIsRestrictedEmailDomain()
		accountSetUpObj = {};
		accountCreationObj ={};
		this.setState({
			authCode : "",
			serialNum : ""
		})
		}
		if(event.target.name === "serialNum"){
			let regex = /^[a-zA-Z0-9_]+$/;
			if(!regex.test(event.target.value.trim())){
				if(this.state.validateSerialNumberUI === false){
					this.setState({validateSerialNumberUI : !this.state.validateSerialNumberUI})
				}
			}
			else{
				this.setState({validateSerialNumberUI : false})
			}
		}
		this.props.removeRadioButtonsError();
		this.setState({[event.target.name]: event.target.value},() =>{this.checkUserrole(event)});
		
		if(event.target.name === "authCode" || event.target.name === "serialNum"){
			accountCreationObj = {}
		}
		if(event.target.name === "country"){
			let index = event.nativeEvent.target.selectedIndex;
			let label = event.nativeEvent.target[index].text;
			this.setState({
				countryName : label
			},() => {
				this.setPhoneCode()
				this.getDomainDetail(event)
			});
			
		}
		this.validateEmail(event);
		this.checkForEngChar(event);
	}
	
   setPhoneCode(){
		if(this.state.countryName !== "- Please Specify -"){
			let obj = this.props.countries.find((value) => {
				return value.countryName === this.state.countryName
			})
			this.setState({
				phoneCode : obj.phoneCode
			})
			accountCreationObj && (accountCreationObj.phoneCode = obj.phoneCode)
		}
		else this.setState({phoneCode: null})
   }

   /**
	* 
	* @param {Input: select box dom object} event 
	*/
   getDomainDetail = (event) => {
	this.fetchUserRole(event);
   }

	/*****************************************************************
     * Triggered : On blur of email address text box
     * Description : This function is used to validate email address.
     ******************************************************************/
	validateEmail(event) {	
			// eslint-disable-next-line
			let letters = /^\w+([\.]?[-]?[\.-]\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/;
			//letters = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/;
		if (event.target.name === "emailAdd") {
			if (event.target.value.match(letters)) {
				if(event.target.value.endsWith("@juniper.net", event.target.value.length) ||event.target.value.endsWith("@jnpr.net", event.target.value.length)){
					this.setState({ incorrectEmailAdd: false, errorEmailInternalUsers:true },()=>{
						this.validateMandatoryFieldsAndError();
					});
				}else{
					this.setState({ incorrectEmailAdd: false, errorEmailInternalUsers:false },()=>{
						this.validateMandatoryFieldsAndError();
					});
				}
			} else {
				this.setState({ incorrectEmailAdd: true },()=>{
					this.validateMandatoryFieldsAndError();
				});
			}
			if (this.state.renterEmailAdd !== "" && this.state.renterEmailAdd !== event.target.value) {
				this.setState({ errorEmailDontMatch: true },()=>{
					this.validateMandatoryFieldsAndError();
				});
			} else {
				this.setState({ errorEmailDontMatch: false },()=>{
					this.validateMandatoryFieldsAndError();
				});
			}
			//API Validation for public domain | Email Already Exists
			if(event.target.value.match(letters) && (!event.target.value.endsWith("@juniper.net", event.target.value.length) && !event.target.value.endsWith("@jnpr.net", event.target.value.length))){
				this.isEmailAPublicDomain(event.target.value);
			}
		}
		if (event.target.name === "renterEmailAdd") {
			if (event.target.value.match(letters)) {
				if(event.target.value.endsWith("@juniper.net", event.target.value.length) || event.target.value.endsWith("@jnpr.net", event.target.value.length)){
					this.setState({ incorrectRenterEmailAdd: false,errorReEmailInternalUsers: true},()=>{
					this.validateMandatoryFieldsAndError();
				});
			}else{
				this.setState({ incorrectRenterEmailAdd: false,errorReEmailInternalUsers: false},()=>{
					this.validateMandatoryFieldsAndError();
				});
			}
			} else {
				this.setState({ incorrectRenterEmailAdd: true },()=>{
					this.validateMandatoryFieldsAndError();
				});
			}
			if (this.state.emailAdd !== "" && this.state.emailAdd !== event.target.value) {
				this.setState({ errorEmailDontMatch: true },()=>{
					this.validateMandatoryFieldsAndError();
				});
			} else {
				this.setState({ errorEmailDontMatch: false },()=>{
					this.validateMandatoryFieldsAndError();
				});
			}
		}
	}

 	/*****************************************************************
     * Triggered : toggleGuestUserBox()
     * Description : This function is used to show/hide guest user access
	 * 				 box on the basis of radio button.
	 * 				 i.e. For Guest User Access : Display
	 * 				 & for all other radio button clicks : Hide
     ******************************************************************/
	toggleGuestUserBox(event){
		this.props.removeRadioButtonsError();
		accountCreationObj = {}
		if(event.target.checked){
			this.setState({
				[event.target.name] : event.target.value},()=>{
					this.validateMandatoryFieldsAndError();
			})
			if(event.target.value === 'Authorization Code'){
				this.setState({disableAuthCode : false})
				this.setState({disableSerialNum : true})
				this.setState({validateSerialNumberUI : false});
					setTimeout(() => this.authInput.current.focus(),0)
			}else if(event.target.value === 'Product Serial Number'){
				this.setState({disableAuthCode : true})
				this.setState({disableSerialNum : false})
				setTimeout(() => this.serialInput.current.focus(),0)
			}else{
				this.setState({disableAuthCode : true})
				this.setState({disableSerialNum : true})
				this.setState({validateSerialNumberUI : false});
			}
		}
		if(event.target.value !== "Authorization Code"){
			this.setState({
				authCode : ""
			})
		}
		if(event.target.value !== "Product Serial Number"){
			this.setState({
				serialNum : ""
			})
		}
	}
	/*****************************************************************
     * Function : 	 renderCountryList()
     * Description : This function is used to generate country dropdown
	 * 				 options with list fetched from api.
     ******************************************************************/
	renderCountryList(countries){
		return countries.map((country,index)=>{
			return <option key={"country"+index} value={country.countryCode} disabled={EMBARGO_COUNTRY_LIST.includes(country.countryName)}>{country.countryName}</option>
		})
	}
	/*****************************************************************
     * Triggered : checkUserRole()
     * Description : This function is used to show/hide radio buttons
	 * 				  on the basis of user types onChange of all fields
     ******************************************************************/
	checkUserrole(event){
	const {emailAdd} = this.state;
	if((event.target.name === "emailAdd" || event.target.name === "renterEmailAdd" || event.target.name ==="country") 
	&& this.state.emailAdd !== undefined && this.state.emailAdd.trim() !== "" && this.state.renterEmailAdd !== undefined
	&& this.state.renterEmailAdd.trim() !== "" 
	&& this.state.country !== -1 && this.state.country !== "-1" && !this.state.errorEmailDontMatch){
		//Check for Juniper Domain | UI End check
		if ((emailAdd.indexOf("@juniper.net") >= 0 || emailAdd.indexOf(".juniper.net") >= 0) 
		&& emailAdd.indexOf("@jtac.juniper.net") < 0 && !this.state.errorEmailDontMatch) {
			this.setState({
				isJuniperDomain : true,
				dialogName : 'juniper-email-domain'
			})
		}else{
			this.setState({
				isJuniperDomain : false,
				dialogName : 'public-email-domain',
			})
		}
	}
	}

	/*****************************************************************
     * Triggered : On change of any Input form field
     * Description : This function is used to validate whether input provided
     *               is in english or not.
     ******************************************************************/
    checkForEngChar(event){
        var isFieldContainsEnglishCharacter = isEnglishChar(event);
        var errorString = "errorNonEng" + event.target.name;
        if(!isFieldContainsEnglishCharacter){
            this.setState({[errorString] : true});
        }else{
            this.setState({[errorString] : false});
        }
	}
	
	/**************************************************************
	 * 	Description : This function calls an API /getUserType
	 * 				  to find out whether user is :-
	 * 				  1. End User :- Display radio buttons
	 * 				  2. Customer :- Hide Radio buttons
	 **************************************************************/
	fetchUserRole(event){
		let d  = document.getElementById("authInputId")
		if(d) d.click();
		var url = "/getUserTypeInfo";
		//if inbox is empty then retun
		//if(!this.state.authCode) return;

		var payLoad = {
			"emailAddress": this.state.emailAdd,
			"emailDomain": this.props.emailDomain?this.props.emailDomain.emailDomain : "@"+this.state.emailAdd.split("@")[1].replace(/ /g,''),
			"authorizationCode": this.state.authCode || null,
			"countryCode": this.state.country
		}
		this.props.fetchUserType(url,payLoad)
				   .then(res => {
					if(res.userType === "Customer") {
						this.props.loadHideRadioButtons(true)
						this.setState({ showUserTypeRadioBtn: false })
					} else {
						this.props.loadHideRadioButtons(false)
						this.setState({ showUserTypeRadioBtn: true })
					}

				   })
				   .catch(err => console.log(err));
	}

	/**************************************************************
	 * 	Description : This function calls an API /getUserType
	 * 				  to find out whether Email is a public domain
	 * 				  or not.
	 **************************************************************/
	isEmailAPublicDomain(emailAddress){
		var url = "/isPublicEmailDomain";
		var payLoad = {
			"emailAddress" : emailAddress
		}
		this.props.isEmailAPublicDomain(url,payLoad);
	}

	/**************************************************************
	 * 	Description : This function calls an API /getUserType
	 * 				  to find out whether user Account already exists 
	 * 				  or not.
	 **************************************************************/
	isEmailAlreadyExist(emailAddress){
		var url = "/isEmailExists";
		var payLoad = {
			"emailAddress" : emailAddress
		}
		this.props.isEmailAlreadyExist(url,payLoad);
	}

	isRestrictedEmailDomain(emailDomain){
		var url = "/isRestrictedEmailDomain?emailDomain="+ emailDomain
		this.props.isRestrictedEmailDomain(url)
	}

	validateEmailExist(event){
		// eslint-disable-next-line
		let letters = /^\w+([\.]?[-]?[\.-]\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/;

		if(event.target.value.match(letters) && (!event.target.value.endsWith("@juniper.net", event.target.value.length) && !event.target.value.endsWith("@jnpr.net", event.target.value.length))){
			this.isRestrictedEmailDomain("@"+event.target.value.split("@")[1].replace(/ /g,''))
		}
	}

	resetValues(){
		this.props.resetValues()
	}

	resetElevateAuthError(){
		this.props.resetElevateAuthError()
	}

	resetIsRestrictedEmailDomain(){
		this.props.resetIsRestrictedEmailDomain()
	}

	validateAuthCode(authCode){
		let url = "/getCompanyFromAuthCode";
		let payload = {
			"authCode" : authCode
		}
		if(!this.state.errorNonEngauthCode && this.state.authCode !== ""){
		this.props.validateAuthCode(url,payload);
		}
	}
	validateSerialNum(serialNum){
		let url = "/validateSerialNumber";
		let payload = {
			"serialNumber" : serialNum
		}
		return this.props.validateSerialNum(url,payload)
	}

	getNextBtnClass(){
		if((this.props.isEmailPublicDomain === "True"  && !this.state.enableNextBtn) || this.props.loadingStarts || this.state.validateSerialNumberUI || this.state.errorOnPage || this.props.isEmailAlreadyExists === "True" || this.props.isRestrictedEmailDomains === "YES" || this.props.authCode === "Invalid Authorization Code" || this.props.authCode === "Invalid Role"|| (( this.props.isEmailPublicDomain === "True") && this.state.accountSetUpRadioCollection === "Authorization Code" && this.state.authCode === "") || this.props.authInputLoading || (this.state.accountSetUpRadioCollection === "Product Serial Number" && this.state.serialNum === "") || this.props.serialNumErrMsg === false || this.props.serialNumLoading || this.state.accountSetUpRadioCollection === "" || this.props.loadingSpinner || this.props.elevateCircleCodeInputLoading || this.props.elevateValidateError || (this.state.accountSetUpRadioCollection === "Authorization Code" && this.state.authCode === "" && this.state.showUserTypeRadioBtn) || this.state.elevateCircleInputError || this.state.captchaToken == null){
			return "primary disabled"
		}
		else{
			return "primary"
		}
	}
	// toggleLabUser(){
	// 	this.setState({
	// 		isChecked : !this.state.isChecked
	// 	})
	// }
	toggleGroupEmail(event){
		this.setState({
			emailFlag : event.target.value
		})
		if(event.target.value === "generic"){
			this.setState({
				openEmailDialog : true
			})
		}
	}
	closeEmailDialog(){
		this.setState({
			openEmailDialog : false
		})
	}

	/**
	 * This is used to handle elevate circle code check box
	 * @param {Input object} e 
	 */
	handleElevateCircleCheck = (e) => {
		let elevatedCircleCodeChek = false
		let elevatedCircleCode = this.state.elevatedCircleCode;
		if(e.target.checked) elevatedCircleCodeChek = true

		if(!elevatedCircleCodeChek) {elevatedCircleCode = ""; this.resetElevateAuthError() }

		// if user uncheck then we have to reset error from input
		if(!elevatedCircleCodeChek) {
			this.setState({ elevateInputError: "" })
			this.props.resetElevateErrorMsg();
		}

		let stateObj = {elevatedCircleCodeChek, elevatedCircleCode};

		// if elevate circle checkbox check and value is empty then, disable next button
		if(elevatedCircleCodeChek && !this.state.elevatedCircleCode) {
			stateObj.elevateCircleInputError = true
		} else {
			stateObj.elevateCircleInputError = false
		}

		//if elevate auth code checked, then auto select Guest User Access
		if(e.target.checked && !this.state.authCode && !this.state.serialNum) {
			if(this.state.showUserTypeRadioBtn) stateObj.accountSetUpRadioCollection = "Guest User Access"
			stateObj.disableAuthCode = true
			stateObj.disableSerialNum = true

			stateObj.authCode = ""
			stateObj.serialNum = "" 
		}
		this.setState(stateObj,()=>{
			this.validateMandatoryFieldsAndError();
		});

	}

	/**
	 * This is used to handle elevate circle code input
	 * @param {Input Object} e 
	 */
	handleChangeElevateCircleCode = (e) => {
		this.props.resetElevateErrorMsg()
		this.setState({ elevatedCircleCode : e.target.value.trim(),  elevateInputError: false, elevateCircleInputError: e.target.value.trim()? false : true }, () => {
			this.validateMandatoryFieldsAndError();
		})
	}

	/**
	 * This is used to handle on blur on elevated circle code
	 * @param {On Blur Object} e 
	 * @returns 
	 */
	handleOnBlurElevateCircleCode = () => {
		if(!this.state.elevatedCircleCode || this.state.elevatedCircleCode === "") {
			this.navigateToAcctCreationOrCloudProvider(false)
			return;
		}

		this.props.validateElevateCircleCode({ elevatedCircleCode: this.state.elevatedCircleCode })
			.then(res => {
				if(res.ResMessage === "Success") {
					this.navigateToAcctCreationOrCloudProvider(false)
				}
			})
			.catch(err => {
				return false
			})

		return true
	}

	verifyCaptcha = (response) => { 
		this.setState({...this.state, captchaToken: response})
    } 

	
    

	render() {

		const { countries, isEmailPublicDomain, isEmailAlreadyExists, isRestrictedEmailDomains,authCode,serialNumErrMsg,serialNumLoading,authInputLoading,loadingSpinner, elevateCircleCodeInputLoading, elevateValidateError} = this.props;
		const {elevatedCircleCodeChek, elevatedCircleCode, elevateInputError, showUserTypeRadioBtn} = this.state

		return (
			<div>
				<div className="grid padding-around font-size-medium center-element width-80">
				<ReactTooltip delayHide={100} effect="solid" className="tooltipColor" place="top" html={true} />
					<div className="col-12">
						<p className="font-size-xlarge text-uppercase"><strong>Things to Consider</strong></p>
						<p>While you are registering for a customer or guest user account, keep these tips in mind: </p>
						<ul>
							<li>Guests may need to pass additional compliance checks for access to virtual lab and learning tools (e.g., vLabs, JCL).</li>
							<li>Ensure you provide accurate personal information to pass Juniper’s compliance screening. The address and Zip/Postal Code need to be valid and in the correct format.</li>
							<li>Use English characters only. You will not be able to create an account using non-English characters (e.g., Asian languages and European characters).</li>
						</ul>
						
						<div className="padding-top">
							<div className="grid">
								<div className="col-6">
									<p className="text-input-label margin-bottom">Email Address <span className="text-required">*</span> <span className="padding-top-smaller text-hint">(this will be your Juniper Account ID)</span></p>
									<div className="field-format">
										<input type="text" placeholder="Type here..." name="emailAdd" value={this.state.emailAdd} 
										onChange={this.onChangeField} 
										onBlur={this.validateEmailExist.bind(this)} required disabled={true} />
										<BarLoader  width={"50%"} color={'#84B135'} loading={this.props.loadingStarts} />
										{isRestrictedEmailDomains === "YES" && <p className="font-size-small text-color-red"><i className="fa fa-sm fa-exclamation-circle"></i> Email Domain Restricted!</p>}
										{ this.state.erroremailAdd && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i> Please provide Email Address!</p>}
										{ !this.state.erroremailAdd && this.state.incorrectEmailAdd && <p className="font-size-small text-color-red"><i className="fa fa-sm fa-exclamation-circle"></i> Invalid Email Address!</p>}
										{ !this.state.erroremailAdd && !this.state.incorrectEmailAdd && this.state.errorEmailInternalUsers && <p className="font-size-small text-color-red"><i className="fa fa-sm fa-exclamation-circle"></i> Internal Users are Restricted!</p>}
										{!this.state.erroremailAdd && !this.state.incorrectEmailAdd && isEmailAlreadyExists === "True" && <p className="font-size-small text-color-red"><i className="fa fa-sm fa-exclamation-circle"></i> Email address you entered is already registered. If required, you could <span className='hyperlink' onClick={() => this.props.history.push({pathname : "/resetpassword"})}>reset your password.</span> If you continue to have issues logging in, please <span className='hyperlink' onClick={() => window.open('https://www.juniper.net/support/requesting-support.html')}>contact customer support</span> for further assistance.</p>}
									</div>
								</div>
								<div className="col-6">
									<p className="text-input-label margin-bottom">Re-enter Email Address <span className="text-required">*</span></p>
									<div className="field-format">
										<input type="text" placeholder="Type here..." name="renterEmailAdd" disabled={true} value={this.state.renterEmailAdd} 
										onChange={this.onChangeField}
										onPaste={this.preventPasting}
										required />
										{ this.state.errorrenterEmailAdd && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i> Please Re-enter Email Address!</p>}
										{ !this.state.errorrenterEmailAdd && !this.state.incorrectRenterEmailAdd && this.state.errorEmailDontMatch && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i> Email Address and Re-entered Email Address must match.</p> } 
										{ !this.state.errorrenterEmailAdd && this.state.incorrectRenterEmailAdd && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i> Invalid Email Address!</p>}
										{ !this.state.errorrenterEmailAdd && !this.state.incorrectRenterEmailAdd && !this.state.errorEmailDontMatch &&this.state.errorReEmailInternalUsers && <p className="font-size-small text-color-red"><i className="fa fa-sm fa-exclamation-circle"></i> Internal Users are Restricted!</p>}
									</div>
								</div>
							</div>
							{/*Radio buttons for group and individual email*/}
							<div className="grid">
								<div className="col-6">
									<span className="margin-right">
										<input type="radio" value="individual" checked={this.state.emailFlag === "individual"}
										name="isGroupEmail"  onChange={this.toggleGroupEmail.bind(this)}/> Individual Email
									</span>
									<span>
										<input type="radio" value="generic" checked={this.state.emailFlag === "generic"}
										name="isGroupEmail"  onChange={this.toggleGroupEmail.bind(this)} /> Group Email</span>
								 </div>
							</div>
							<div className="grid">
								<div className="col-6">
									<p className="text-input-label margin-bottom">Country <span className="text-required">*</span></p>
									<div className="field-format">
										<select className="no-margin-top" onChange={this.onChangeField} value={this.state.country} name="country" required>
											<option value={-1}>- Please Specify -</option>
											{this.renderCountryList(countries)}
										</select>
										<BarLoader  width={"50%"} color={'#84B135'} loading={loadingSpinner} />
										{ this.state.errorcountry && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i> Please provide Country!</p> }
									</div>
								</div>
								<div className="col-6"></div>
							</div>
						</div>
						<div>
						</div>
						<div className={`${showUserTypeRadioBtn? "padding-top-large" :""}`}>
							{showUserTypeRadioBtn && <div className="usertype-radio-butto">
							<ReactTooltip delayHide={100} effect="solid" className="tooltipColor" place="top" html={true} />
							<div className="grid">
								<div className="col-6">
									<input type="radio" value="Authorization Code" 
									name="accountSetUpRadioCollection"  onChange={this.toggleGuestUserBox}  checked = {this.state.accountSetUpRadioCollection === "Authorization Code"}/> Use an Authorization Code
								</div>
								<div className="col-6 flex">
									<div className="width-input margin-right">
									<input className="field-format" type="text" id="authInputId" placeholder="Enter Authorization Code here..." name="authCode" value={this.state.authCode} onChange={this.onChangeField} 
									disabled={authInputLoading || this.state.disableAuthCode}  ref={this.authInput} />
									<BarLoader  width={"50%"} color={'#84B135'} loading={authInputLoading} />
									{this.state.errorNonEngauthCode && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;System only accepts English characters. Please update and retry!</p>}
									{authCode === "Invalid Authorization Code" && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;Invalid Authorization Code. Please reach out to your account administrator or <span className='hyperlink' onClick={() => window.open('https://www.juniper.net/support/requesting-support.html')}>contact customer support</span> for further assistance.</p>}
									{authCode === "Invalid Role" && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;
									Option to use Authorization Code is not available for Partner contacts. For More details, please visit <span className='hyperlink' onClick={() => window.open('https://juniper.my.site.com/prm/s/help')}>Partner Assistance </span>Page</p>}
									</div>	
									<div>
									<i data-tip="Select this option if you are a Supplier or if your company has a Customer Support Profile with Juniper. Enter your Supplier or Customer Authorization Code and your account will be linked to your customer records.
									<br><br>If you are a Supplier or Profile customer, but you do not have your Authorization Code, please contact your Juniper Partner Account Manager or the Juniper 
									<span class='pseudolink' onClick={window.open('https://www.juniper.net/support/requesting-support.html')}> Customer Care Team</span> for assistance." className="fa fa-question-circle fa-lg margin-left tooltip-display" style={{color:"#84B135",marginLeft: 0}}>
									</i></div>
								</div>
							</div>
							<div className="grid">
								<div className="col-6">
									<input type="radio" value="Product Serial Number" name="accountSetUpRadioCollection" onChange={this.toggleGuestUserBox} checked = {this.state.accountSetUpRadioCollection === "Product Serial Number"}/> Use a Product Serial Number or Software Support Reference Number
								</div>
								<div className="col-6 flex">
								<div className="width-input margin-right">
									<input className="field-format" type="text" placeholder="Enter Serial Number here..." name="serialNum" value={this.state.serialNum} onChange={this.onChangeField} 
									disabled={serialNumLoading || this.state.disableSerialNum}  ref={this.serialInput}/>
									<BarLoader  width={"50%"} color={'#84B135'} loading={serialNumLoading} />
									{this.state.errorNonEngserialNum && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;System only accepts English characters. Please update and retry!</p>}
									{(serialNumErrMsg ===false || this.state.validateSerialNumberUI === true) && (this.state.accountSetUpRadioCollection === "Product Serial Number") &&
									<p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;Invalid Product Serial number.Please try again with valid enteries. If you are continuing to face issues, please <span className='hyperlink' onClick={() => window.open('https://www.juniper.net/support/requesting-support.html')}>contact customer support</span> for further assistance.</p>
									}
								</div>
								<div>
								<i data-tip="Select this option to create a login account using a Product Serial Number or Software Support Reference Number (SSRN), which is owned by your company.<br><br>For assistance in locating your Product Serial Number or SSRN, please refer to <span class='pseudolink' onClick={window.open('http://kb.juniper.net/KB11364')}>KB11364.</span>" className="fa fa-question-circle fa-lg margin-left" style={{color:"#84B135",marginLeft: 0}}>
								</i></div>
								</div>
							</div>
							<div className="grid">
								<div className="col-6">
									<input type="radio" value="Cloud Marketplace" name="accountSetUpRadioCollection" onChange={this.toggleGuestUserBox} checked = {this.state.accountSetUpRadioCollection === "Cloud Marketplace"}/> Use your Cloud Marketplace or Cloud Service Provider credentials
									&nbsp;<i data-tip="Select this option if you have purchased a Pay As You Go (PAYG) hourly or yearly product from a Cloud Marketplace. This will provide you with limited access to the Juniper Support Portal, based on the criteria outlined by your cloud provider.<br><br>If you require access to the Juniper Support Portal or Partner Center to be able to use all self-service tools and utilities, you must register using a valid Product Serial Number, Support Reference Number or Authorization Code. " className="fa fa-question-circle fa-lg" style={{color:"#84B135"}}></i>
								</div>
							</div>
							<div className="grid">
								<div className="col-6">
									<input type="radio" value="Lab User Access" name="accountSetUpRadioCollection"  onChange={this.toggleGuestUserBox} checked = {this.state.accountSetUpRadioCollection === "Lab User Access"}/> Access to EngNet, vLabs & other JCL Tools
									&nbsp;<i data-tip="Select this option to gain access to tools such as EngNet, vLabs (https://jlabs.juniper.net/home/) or JCL. If you require access to the Juniper Learning Portal please select “Guest User Access”.
									<br><br>
									Please note that your account will be subject to compliance screening. You must provide accurate and valid information including your name, email, mailing address, and phone number.
									<br><br>
									If you require access to the Juniper Support Portal or Partner Center to be able to use all self-service tools and utilities, you must register using a valid Product Serial Number, Support Reference Number or Authorization Code.  " className="fa fa-question-circle fa-lg" style={{color:"#84B135"}}></i>
								</div>
							</div>

							<div className="grid">
								<div className="col-6">
									<input type="radio" value="Evaluation User Access" name="accountSetUpRadioCollection" onChange={this.toggleGuestUserBox} checked = {this.state.accountSetUpRadioCollection === "Evaluation User Access"}/> Evaluation User Access
									&nbsp;<i data-tip="Includes all permissions associated with Guest User access, as well as, a 60-day Evaluation License. All accounts are subject to export compliance screening.<br><br>If you require access to the Juniper Support Portal or Partner Center to be able to use all self-service tools and utilities, you must register using a valid Product Serial Number, Support Reference Number or Authorization Code." className="fa fa-question-circle fa-lg" style={{color:"#84B135"}}></i>
								</div>
							</div>
							
							<div className="grid">
								<div className="col-6">
									<input type="radio" value="Guest User Access" name="accountSetUpRadioCollection"  onChange={this.toggleGuestUserBox} checked = {this.state.accountSetUpRadioCollection === "Guest User Access"}/> Guest User Access (Juniper Learning Portal, Elevate Community and more)
									&nbsp;<i data-tip="Select this option to gain access to the Juniper Learning Portal for all training, the Elevate Community Forums, or to browse product information and access other collaboration areas.
									<br><br>
    								If you require access to the Juniper Support Portal or Partner Center to be able to use all self-service tools and utilities, you must register using a valid Product Serial Number, Support Reference Number or Authorization Code." className="fa fa-question-circle fa-lg" style={{color:"#84B135"}}></i>
								</div>
							</div>

							<div className="horizontal-line-short">
								<div className="horizontal-line-short-45"></div>
							</div>
						
						</div>}

							<div className={`grid ${showUserTypeRadioBtn? "padding-top-large" : ""}`}>
								<div className="col-6">
									<input type="checkbox" value="elevatedCircleCode" name="elevatedCircleCodeChek" onChange={this.handleElevateCircleCheck} checked = {elevatedCircleCodeChek}/> Elevate Auth Code
									&nbsp;<i data-tip="Select this option to gain access to the private Elevate Community that corresponds to the Auth Code provided to you.<br><br> If you require access to the Juniper Support Portal or Partner Center to be able to use all self-service tools and utilities, you must register using a valid Product Serial Number, Support Reference Number or Authorization Code." className="fa fa-question-circle fa-lg" style={{color:"#84B135"}}></i>
								</div>

								{elevatedCircleCodeChek && <div className="col-6 flex">
									<div className="width-input margin-right">
										<input className="field-format" type="text" placeholder="Enter Elevate Circle Code here..." name="elevateCircleCode" value={elevatedCircleCode} onChange={this.handleChangeElevateCircleCode} ref={this.elevateCircleCodeInput}/>
										<BarLoader  width={"50%"} color={'#84B135'} loading={elevateCircleCodeInputLoading} />
										
										{elevateValidateError && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp; {elevateValidateError}</p>}
										{elevateInputError && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;  Field cannot be empty.</p>}

									
										{authCode === "Invalid Role" && <p className="font-size-small text-color-red"><i className="fas fa-sm fa-exclamation-circle"></i>&nbsp;
										Option to use Authorization Code is not available for Partner contacts. For More details, please visit <span className='hyperlink' onClick={() => window.open('https://juniper.my.site.com/prm/s/help')}>Partner Assistance </span>Page</p>}
									</div>
								</div>
								}
							</div>

							{/* {this.state.accountSetUpRadioCollection === "Guest User Access" &&
							<div name="guestUserAccessBox" className="col-12 padding-pre" style={{fontSize: 14, border: '1px solid #038BE5', backgroundColor: '#EEF6FA', display:"block"}}>
								<div >You have selected a Guest User Account. Please check the box if you require access to our virtual lab and learning tools (e.g., EngNet, vLabs, JCL, and the Learning Portal). Please note for Guest Users requiring access to these portals your account will be subject to compliance screening.</div><br/>
								<div className="grid flex-horizontal">
								<span className="checkmark"></span>
								</label><div ><b>Access to EngNet, vLabs & other JCL tools</b></div>
								</div>	
						</div>
							}
							</div>
							} */}
						</div>
						<div className="padding-top-largwwe">
							<p className="font-size-large margin-top-large margin-bottom"><strong>We’re here to help </strong></p>
							<p>Additional instructions for user registration are available in the <a href="https://supportportal.juniper.net/s/article/Create-New-User-Login-Account-with-Juniper-Networks?language=en_US" target="_blank" rel="noopener noreferrer">Knowledge Base</a>. If you require special handling of your login account, contact our Customer Care Team for assistance.</p>
						</div>
						{isEmailPublicDomain === "True" &&
						<div className="padding-top">
							<p><i className="fas fa-lg fa-exclamation-triangle text-color-red"></i> <span className="hyperlink" onClick={() => {this.showDialog('public-email-domain')}} ><b style={{color : "red", textDecoration : 'underline'}}>Attention! Please click to read our public email domain notice before continuing.</b></span></p>
						</div>
						}
						<br></br>

						<ReCAPTCHA
							ref={recaptchaRef}
							sitekey={SITE_KEY}
							onChange={this.verifyCaptcha}
						/>	

								
						<div className="padding-top">
							<button onClick={this.state.isJuniperDomain ? () => this.showDialog('juniper-email-domain') : () => this.navigateToAcctCreationOrCloudProvider()} 
							 className={this.getNextBtnClass()}><span className="back">Next &gt; </span>
							</button>
							<div className="posted-note-container show-it" style={{ zIndex: 999, marginLeft: 120, marginTop: -40, position: "absolute" }}>
								<div className="triangle-right" style={{ left: 156, marginTop: 2, position: "absolute", display: "none" }}></div>
							</div>
						</div>
						<FooterHyperlinks history={this.props.history} pageName = {'accountSetUp'}/>

					</div>
				</div>
				<DisclaimerDomainDialog enableNextButton={this.enableNextButton} dialogName = {isEmailPublicDomain === "True" ? "public-email-domain" : this.state.dialogName} openDialog={this.state.openDialog} closeDialog = {this.closeDialog.bind(this)}/>
				<DisclaimerDomainDialog dialogName = "group-email-dialog" openDialog={this.state.openEmailDialog} closeEmailDialog = {this.closeEmailDialog.bind(this)}/>
			</div>

		)
	}

}


/************************************************************************
 * 1. mapStateToProps : This function is used to map state of Redux layer
 * 					 to React layer as props.
 * 					 props value : state
 ***********************************************************************/
function mapStateToProps(state){
	return {
		countries: state.accountsetupReducer.countries,
		userType: state.accountsetupReducer.userType,
		isEmailPublicDomain : state.accountsetupReducer.isEmailPublicDomain,
		isEmailAlreadyExists : state.accountsetupReducer.isEmailAlreadyExists,
		isRestrictedEmailDomains : state.accountsetupReducer.isRestrictedEmailDomains,		
		loadingStarts : state.accountsetupReducer.loadingStarts,
		authCode : state.accountsetupReducer.authCode,
		authInputLoading : state.accountsetupReducer.authInputLoading,
		serialNumErrMsg : state.accountsetupReducer.serialNumErrMsg,
		serialNumRes : state.accountsetupReducer.serialNumRes,
		serialNumLoading : state.accountsetupReducer.serialNumLoading,
		loadingSpinner : state.accountsetupReducer.loadingSpinner,
		elevateCircleCodeInputLoading : state.accountsetupReducer.elevateCircleCodeInputLoading,
		elevateValidateError: state.accountsetupReducer.elevateValidateError,
		emailDomain: state.accountsetupReducer.emailDomain,
		recaptchKeys: state.accountsetupReducer.recaptchKeys,
		hideRadioButtons: state.accountsetupReducer.hideRadioButtons,
		unlockedUserInfo: state.changePwdReducer.unlockedUserInfo
		
	}
}

/*************************************************************************
 * 2. mapDispatchToProps : This function lets you create functions that dispatch
 * 						when called, and pass those functions as props to
 * 					    your component.
 *************************************************************************/

const mapDispatchToProps = dispatch => bindActionCreators({
	fetchCountries,
	fetchUserType,
	isEmailAPublicDomain,
	isEmailAlreadyExist,
	isRestrictedEmailDomain,
	resetValues,
	resetElevateAuthError,
	resetIsRestrictedEmailDomain,
	removeEmailExistError,
	validateAuthCode,
	validateSerialNum,
	removeRadioButtonsError,
	resetSerialNumMsg,
	validateElevateCircleCode,
	showAuthCodeErrorMsg,
	resetElevateErrorMsg,
	getRecaptchaCredentials,
	loadHideRadioButtons,
	getUnlockUserEmail
}, dispatch)

//3. Connect (mapStateToProps | mapDispatchToProps ) to (React Component)
export default connect(mapStateToProps, mapDispatchToProps)(AccountSetUpComponent)

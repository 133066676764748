import { getApiCall, postApiCall } from '../utils/index';
const getUserByUserIdUrl  = "/getUserByUserId";

export const  getLoggedInUserInfo = (url) => {
    return dispatch => {
        getApiCall(url).then(res => {
                  return res.json(); //If call is success then move to next promise
              })
              .then(res => {
                  //dispatch willl call reducer when data is available.
                  if(!res.email) {
                    window.open('/errorpage','_self')
                      return;
                  }
                  dispatch(getUserByUserId(res.email))
             }).catch(err => {
                // Do something for an error here
               // window.open('/errorpage','_self')
              });
            }
}

/**
 * 
 * @param {String: email address} id 
 * @returns 
 */
 export const getUserByUserId = (id) => {
    return dispatch => {
        postApiCall(getUserByUserIdUrl, {emailAddress: id}).then(res => {
                  return res.json(); //If call is success then move to next promise
              })
              .then(res => {
                  //dispatch willl call reducer when data is available.
                    dispatch({ type: "GET_ACCOUNT_INFO", loggedInUserInfo: res });
             }).catch(err => {
                // Do something for an error here
               //window.open('/errorpage','_self')
              });
        }
}

export const validatePwd = (url, payLoad) => {
    return dispatch => {
        dispatch({type : 'OLD_PWD_LOADER', oldPwdValidateLoader : true })
        postApiCall(url, payLoad).then(res => {
            return res.json();
        }).then(res => {
            dispatch({type : 'PASSWORD_RESPONSE' , pwdResponse : res.ValidatePasswordRes.Password})
            dispatch({type : 'OLD_PWD_LOADER', oldPwdValidateLoader : false })
        }).catch(err => {
            // Do something for an error here
            window.open('/errorpage','_self')
          });
    }
}

export const removeError = (passwordFieldName) => {
    if(passwordFieldName === 'oldPwd'){
        return dispatch => {dispatch({type: "REMOVE_OLD_PWD_ERROR"})}
    }
    if(passwordFieldName === 'newPwd'){
        return dispatch => {dispatch({type: "REMOVE_PWD_COMPLEXITY_ERR"})
                            dispatch({type: "REMOVE_NEW_PWD_ERROR"})}
    }
}

export const resetChangePasswordValues = () => {
    return dispatch => {dispatch({type: "RESET_CHANGE_PWD_VALUES"})}
}

export const checkPwdComplexity = (url,payLoad) => {
    return dispatch => {
        dispatch({type : 'NEW_PWD_LOADER', newPwdValidateLoader : true })
        postApiCall(url, payLoad).then(res => {
            return res.json();
        }).then(res => {
            dispatch({type : 'CHECK_PWD_COMPLEXITY' , checkPwdComplexityRes : res.CheckPasswordComplexityRes.Response})
            dispatch({type : 'NEW_PWD_LOADER', newPwdValidateLoader : false })
        }).catch(err => {
            // Do something for an error here
          window.open('/errorpage','_self')
          });
    }
}

export const updatePassword = (url,payLoad,setSubmitButtonDisabled) => {
    return dispatch => {
        postApiCall(url, payLoad).then(res => {
            return res.json();
        }).then(res => {
            let updatedRes;
            if(res.ResMessage === "Success"){
                updatedRes = "Success";
                setSubmitButtonDisabled(false);
            }else if(res.ResMessage === "Failure"){
                if(res.ErrorMessage.indexOf("Old Password is not correct") > 0 ){
                    updatedRes = {
                        errorType : "oldPasswordError",
                        errorMsg : "Old Password is not correct.",
                        ResMessage : "Failure"
                    }
                }else{
                    let newErrorMsg = "";
                    let splitErrorMsg;
                    if(res.ErrorMessage.indexOf("-") > 0){
                        splitErrorMsg = res.ErrorMessage.split("-")[1];
                        newErrorMsg = splitErrorMsg.trim();
                    }
                    updatedRes = {
                        errorType : "newPasswordError",
                        errorMsg : newErrorMsg,
                        ResMessage : "Failure"
                    }
                }
            }
            else if(res.status === "BAD REQUEST"){
                updatedRes = {
                    errorType : "badRequest",
                    errorMsg: "Password should not have parts of your username.",
                    errorMsg1: "Password cannot be any of your last 3 password(s).",
                    ResMessage : "Failure"
                }
                setSubmitButtonDisabled(true);
            }
            
            dispatch({type : 'UPDATE_PASSWORD' , updatePwdRes : updatedRes})
        }).catch(err => {
            // Do something for an error here
             window.open('/errorpage','_self')
          });
    }
}

export const setPassword = (url,payLoad,setSubmitButtonDisabled) => {
    return dispatch => {
        postApiCall(url, payLoad).then(res => {
            return res.json();
        }).then(res => {
            let updatedRes;
            if(res.ResMessage === "Success"){
                updatedRes = "Success";
                setSubmitButtonDisabled(false);
            }
            else if (res.status === "BAD_REQUEST") {
                updatedRes = {
                    errorType : "badRequest",
                    errorMsg: "Password should not have parts of your username.",
                    errorMsg1: "Password cannot be any of your last 3 password(s).",
                    ResMessage : "Failure"
                }
                setSubmitButtonDisabled(true);
            }
            dispatch({type : 'SET_PASSWORD' , setPwdRes : updatedRes})
        }).catch(err => {
            // Do something for an error here
              window.open('/errorpage','_self')
        });
    }
}

export const getUnlockedUserInfo = (url) => {
    return dispatch => {
        dispatch({type : 'GET_UNLOCKINFO_LOADER', unlockInfoLoader : true })
        getApiCall(url).then(res => {
            return res.json();
        }).then(res => {
            dispatch({type : 'GET_UNLOCKED_USER_INFO', unlockedUserInfo : res.unlockUserInfo})
            dispatch({type : 'GET_UNLOCKINFO_LOADER', unlockInfoLoader : false })
        }).catch(err => {
            // Do something for an error here
         window.open('/errorpage','_self')
          });
    }
}